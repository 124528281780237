import React from 'react'
import Button from '../Button'
import DietaryRequirements from '../DietaryRequirements'
import menu from './menu.json'
import style from './styles.module.scss'

const CourseOptions = ({ course, courseOptions, guest, isMealChoicesDisabled, onChangeMealChoice }) => (
    <div className={style.meals}>
        {courseOptions.map((dishName, key) => (
            <Button
                className={style.dishName}
                disabled={isMealChoicesDisabled}
                key={key}
                onClick={() => onChangeMealChoice({ course, dishName, guest: guest.fullName })}
                selected={dishName === guest[course]}
            >
                <div dangerouslySetInnerHTML={{ __html: dishName }} />
            </Button>
        ))}
    </div>
)

const GuestMeals = ({ guest, isMealChoicesDisabled, onChangeMealChoice, patchGuest, patchGuestLoading }) => (
    <div className={style.guest}>
        <h3>{guest.fullName}</h3>

        <div className={style.course}>
            <h4>Starters</h4>
            <CourseOptions course="starters" courseOptions={menu.starters} guest={guest} isMealChoicesDisabled={isMealChoicesDisabled} onChangeMealChoice={onChangeMealChoice} />
        </div>

        <div className={style.course}>
            <h4>Mains</h4>
            <CourseOptions course="mains" courseOptions={menu.mains} guest={guest} isMealChoicesDisabled={isMealChoicesDisabled} onChangeMealChoice={onChangeMealChoice} />
        </div>

        <div className={style.course}>
            <h4>Desserts</h4>
            <CourseOptions course="desserts" courseOptions={menu.desserts} guest={guest} isMealChoicesDisabled={isMealChoicesDisabled} onChangeMealChoice={onChangeMealChoice} />
        </div>

        <div className={style.course}>
            <h4>Dietary Requirements</h4>
            <DietaryRequirements guest={guest} isMealChoicesDisabled={isMealChoicesDisabled} patchGuest={patchGuest} patchGuestLoading={patchGuestLoading} />
        </div>

        <hr className={style.hr} />
    </div>
)

export default GuestMeals