import React from 'react'
import style from './styles.module.scss'

const Schedule = ({ guests }) => (
    <section id="schedule">
        <div className="centre">
            <h2>Schedule</h2>

            {guests[0]?.invitedToWelcomeDrinks && (
                <div className={style.event}>
                    <h3 className="cursive">
                        <span className={style.eventDate}>Friday 14th July</span>
                        <span className={style.eventPlace}>The Blue Lion, Great Baddow</span>
                    </h3>

                    <div className={style.part}>
                        <h4>Welcome Drinks</h4>
                        <p>6:00pm</p>
                        <img src={require('../../img/scheduleicon1.png')} alt="" className={style.partIcon} />
                    </div>

                    {/* <img src={require('../../img/flowers3.png')} alt="" className={style.flowersLeft} /> */}
                </div>
            )}

            {(guests[0]?.invitedToCeremony || guests[0]?.invitedToReception) && (
                <div className={style.event}>
                    <h3 className="cursive">
                        <span className={style.eventDate}>Saturday 15th July</span>
                        <span className={style.eventPlace}>The Warren Estate</span>
                    </h3>

                    {guests[0]?.invitedToCeremony && (
                        <>
                            <div className={style.part}>
                                <h4>Day Guests Arrive</h4>
                                <p>12:30pm</p>
                                <img src={require('../../img/scheduleicon2.png')} alt="" className={style.partIcon} />
                            </div>

                            <div className={style.part}>
                                <h4>Wedding Ceremony</h4>
                                <p>1:30pm</p>
                                <img src={require('../../img/scheduleicon3.png')} alt="" className={style.partIcon} />
                            </div>

                            <div className={style.part}>
                                <h4>Canapés, Drinks & Photos</h4>
                                <p>2:00pm</p>
                                <img src={require('../../img/scheduleicon4.png')} alt="" className={style.partIcon} />
                            </div>

                            <div className={style.part}>
                                <h4>Wedding Breakfast</h4>
                                <p>4:00pm</p>
                                <img src={require('../../img/scheduleicon5.png')} alt="" className={style.partIcon} />
                            </div>
                        </>
                    )}

                    {guests[0]?.invitedToReception && (
                        <>
                            <div className={style.part}>
                                <h4>Evening Guests Arrive</h4>
                                <p>6:30pm</p>
                                <img src={require('../../img/scheduleicon6.png')} alt="" className={style.partIcon} />
                            </div>

                            <div className={style.part}>
                                <h4>Live Band & Party</h4>
                                <p>7:00pm</p>
                                <img src={require('../../img/scheduleicon7.png')} alt="" className={style.partIcon} />
                            </div>

                            <div className={style.part}>
                                <h4>Carriages</h4>
                                <p>Midnight</p>
                                <img src={require('../../img/scheduleicon8.png')} alt="" className={style.partIcon} />
                            </div>
                        </>
                    )}
                </div>
            )}

            {guests[0]?.invitedToMorning && (
                <div className={style.event}>
                    <h3 className="cursive">
                        <span className={style.eventDate}>Sunday 16th July</span>
                        <span className={style.eventPlace}>The Warren Estate</span>
                    </h3>

                    <div className={style.part}>
                        <h4>Morning After the Night Before</h4>
                        <p>11:00am</p>
                        <img src={require('../../img/scheduleicon9.png')} alt="" className={style.partIcon} />
                    </div>
                </div>
            )}
        </div>
    </section>
)

export default Schedule