import React from 'react'
import Button from '../../components/Button'
import Input from '../../components/Input'
import style from './styles.module.scss'

const InvitationCode = ({ getGuests, getGuestsLoading, guests, invitationCode, invitationCodeError, setInvitationCode }) => {
    const onSubmit = e => {
        e.preventDefault()
        getGuests(invitationCode, true)
    }

    return (
        <section id="invitation-code">
            <div className="centre">
                <h2>Invitation Code</h2>

                <p>To RSVP and review your meal choices, please enter your invitation code:</p>

                {!!invitationCodeError && <p className="error" role="alert">{invitationCodeError}</p> }

                <form className={style.form} onSubmit={onSubmit}>
                    <Input
                        className={style.invitationCode}
                        max={999999}
                        min={0}
                        onChange={e => setInvitationCode(e.target.value)}
                        placeholder="______"
                        type="number"
                        value={invitationCode}
                    />
                    <Button
                        className={style.submitBtn}
                        disabled={getGuestsLoading}
                        el="input"
                        loading={getGuestsLoading}
                        selected={true}
                    >
                        {getGuestsLoading ? '' : !!guests.length ? '\u{2713}' : 'Submit'}
                    </Button>
                </form>
            </div>
        </section>
    )
}

export default InvitationCode