import React from 'react'
import style from './styles.module.scss'

const Button = ({
    children,
    className,
    el = 'button',
    loading = false,
    selected = false,
    tick = true,
    ...rest
}) => (
    <>
        {el === 'button' && (
            <button
                className={`${style.button} ${selected ? style.selected : undefined} ${className}`}
                {...rest}
            >
                {children}
                {selected && tick && ' \u{2713}'}
            </button>
        )}

        {el === 'input' && (
            <input
                className={`${style.button} ${loading ? style.loading : undefined} ${selected ? style.selected : undefined} ${className}`}
                type="submit"
                value={children}
                {...rest}
            />
        )}
    </>
)

export default Button