import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import style from './styles.module.scss'

const CountdownWrapper = ({ weddingDate }) => {
    return (
        <div className={style.countdown}>
            <Countdown
                date={weddingDate}
                // date={Date.now() + 3000}
                renderer={({ days, hours, minutes, seconds, completed }) => (
                    <>
                        {completed ? (
                            <p className={style['wedding-day']}>It's the wedding day!</p>
                        ) : (
                            <div className={style.row}>
                                <div className={style.column}>
                                    <span className={style.number}>{days}</span>
                                    <span className={style.period}>day{days !== 1 && 's'}</span>
                                </div>
                                <div className={style.column}>
                                    <span className={style.number}>{hours}</span>
                                    <span className={style.period}>hour{hours !== 1 && 's'}</span>
                                </div>
                                <div className={style.column}>
                                    <span className={style.number}>{zeroPad(minutes)}</span>
                                    <span className={style.period}>minute{minutes !== 1 && 's'}</span>
                                </div>
                                <div className={style.column}>
                                    <span className={style.number}>{zeroPad(seconds)}</span>
                                    <span className={style.period}>second{seconds !== 1 && 's'}</span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            />
        </div>
    )
}

export default CountdownWrapper