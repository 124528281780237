import { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Loader from '../components/Loader'
import TheWeddingDay from '../sections/TheWeddingDay'
import InvitationCode from '../sections/InvitationCode'
import Rsvp from '../sections/Rsvp'
import MealChoices from '../sections/MealChoices'
import SongRequests from '../sections/SongRequests'
import Schedule from '../sections/Schedule'
import LocalAccommodation from '../sections/LocalAccommodation'
import LocalTaxis from '../sections/LocalTaxis'
import Gifts from '../sections/Gifts'
// import Faq from '../sections/Faq'
import Contact from '../sections/Contact'

const Home = () => {
    const weddingDate = new Date('2023-07-15T11:30:00.000Z')
    const [invitationCode, setInvitationCode] = useState('')
    const [invitationCodeError, setInvitationCodeError] = useState('')
    const [guests, setGuests] = useState([])
    const [getGuestsLoading, setGetGuestsLoading] = useState(false)
    const [patchGuestLoading, setPatchGuestLoading] = useState(false)
    const [flowersColourSwap, setFlowersColourSwap] = useState(false)

    const isMealChoicesVisible = true
    const disableRsvpDate = new Date('2099-07-14T23:59:59.000Z')
    const disableMealChoicesDate = new Date('2099-07-01T23:59:59.000Z')
    const [isRsvpDisabled, setIsRsvpDisabled] = useState(false)
    const [isMealChoicesDisabled, setIsMealChoicesDisabled] = useState(false)

    useEffect(() => {
        // Auto-fill invitationCode
        const invitationCodeLS = localStorage.getItem('invitationCode')
        const invitationCodeParams = window.location.search?.split('=')[1]

        if (invitationCodeLS) {
            setInvitationCode(invitationCodeLS)
            getGuests(invitationCodeLS)
        } else if (invitationCodeParams) {
            setInvitationCode(invitationCodeParams)
            getGuests(invitationCodeParams)
        }

        // Check if forms need disabling
        if (new Date() - disableRsvpDate > 0) {
            setIsRsvpDisabled(true)
        }

        if (new Date() - disableMealChoicesDate > 0) {
            setIsMealChoicesDisabled(true)
        }
    }, [])

    const getGuests = async (invitationCode, fromOnSubmit) => {
        setInvitationCodeError('')

        if (isNaN(invitationCode) || invitationCode.length < 6) {
            setInvitationCodeError('Please enter your 6-digit invitation code.')
            return
        }

        try {
            setGetGuestsLoading(true)

            // const res = await fetch(`https://doherty-rowe-wedding.co.uk/api/getGuests.php?i=${invitationCode}`)
            const res = await fetch(`https://dohertyrowe.jamesalexanderlee.co.uk/api/test/getGuests.php?i=${invitationCode}`)

            if (!res.ok) {
                if (res.status === 404) {
                    setInvitationCodeError('The invitation code you have entered was not found. Please check for typos and try again.')
                } else {
                    setInvitationCodeError('Something went wrong! Please try again or contact hello@doherty-rowe-wedding.co.uk.')
                }
                setGetGuestsLoading(false)
                return
            }

            const rows = await res.json()

            if (rows) {
                setGuests(rows)
                setGetGuestsLoading(false)
                localStorage.setItem('invitationCode', invitationCode)

                if (rows[0].invitedToCeremony) {
                    setFlowersColourSwap(true)
                }

                if (fromOnSubmit) {
                    setTimeout(() => window.location.hash = 'rsvp', 700)
                }
            }
        } catch (err) {
            setInvitationCodeError(`Something went wrong! Please try again or contact hello@doherty-rowe-wedding.co.uk with the following error: ${err.toString()}`)
            setGetGuestsLoading(false)
            console.error(err)
        }
    }

    const patchGuest = async ({ column, fullName, value }) => {
        if (column === undefined || fullName === undefined || value === undefined) {
            return
        }

        try {
            setPatchGuestLoading(true)

            // const res = await fetch('https://doherty-rowe-wedding.co.uk/api/patchGuest.php', {
            const res = await fetch('https://dohertyrowe.jamesalexanderlee.co.uk/api/test/patchGuest.php', {
                body: JSON.stringify({ column, fullName, invitationCode, value }),
                headers: { 'Content-Type': 'application/json' },
                method: 'PATCH',
            })

            if (!res.ok) {
                alert('Something went wrong! Please try again or contact hello@doherty-rowe-wedding.co.uk with the following error: Error updating guests')
                setPatchGuestLoading(false)
                return
            }

            const rows = await res.json()

            if (rows) {
                setGuests(rows)
            }

            setPatchGuestLoading(false)
        } catch (err) {
            alert(`Something went wrong! Please try again or contact hello@doherty-rowe-wedding.co.uk with the following error: ${err.toString()}`)
            setPatchGuestLoading(false)
            console.error(err)
        }
    }

    const onChangeMealChoice = ({ course, dishName, guest }) => {
        patchGuest({ column: course, fullName: guest, value: dishName })
    }

    return (
        <>
            <Header guests={guests} />

            <main>
                <TheWeddingDay weddingDate={weddingDate} />
                <InvitationCode getGuests={getGuests} getGuestsLoading={getGuestsLoading} guests={guests} invitationCode={invitationCode} invitationCodeError={invitationCodeError} setInvitationCode={setInvitationCode} />
                {!!guests.length && (
                    <>
                        <Rsvp guests={guests} isRsvpDisabled={isRsvpDisabled} patchGuest={patchGuest} patchGuestLoading={patchGuestLoading} />
                        {guests[0].invitedToCeremony && <MealChoices guests={guests} isMealChoicesDisabled={isMealChoicesDisabled} isMealChoicesVisible={isMealChoicesVisible} onChangeMealChoice={onChangeMealChoice} patchGuest={patchGuest} patchGuestLoading={patchGuestLoading} />}
                        <SongRequests guests={guests} patchGuest={patchGuest} patchGuestLoading={patchGuestLoading} />
                        <Schedule guests={guests} />
                    </>
                )}
                <LocalAccommodation flowersColourSwap={flowersColourSwap} />
                <LocalTaxis flowersColourSwap={flowersColourSwap} />
                <Gifts flowersColourSwap={flowersColourSwap} />
                {/* <Faq /> */}
                <Contact flowersColourSwap={flowersColourSwap} />
                <Loader patchGuestLoading={patchGuestLoading} />
            </main>

            <Footer />
        </>
    )
}

export default Home