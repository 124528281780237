import React from 'react'
import Button from '../Button'
import style from './styles.module.scss'

const GuestRsvp = ({ guest, isRsvpDisabled, patchGuest, patchGuestLoading, sheetKey }) => (
    <div className={style.guestRsvp}>
        <span className={style.guestName}>{guest.fullName}</span>
        <div className={style.guestRsvpButtons}>
            <Button
                className={style.guestRsvpButton}
                disabled={patchGuestLoading || isRsvpDisabled}
                loading={patchGuestLoading}
                onClick={() => patchGuest({ column: sheetKey, fullName: guest.fullName, value: true })}
                selected={guest[sheetKey] === true}
            >
                Will Attend
            </Button>
            <Button
                className={style.guestRsvpButton}
                disabled={patchGuestLoading || isRsvpDisabled}
                loading={patchGuestLoading}
                onClick={() => patchGuest({ column: sheetKey, fullName: guest.fullName, value: false })}
                selected={guest[sheetKey] === false}
            >
                Will Not Attend
            </Button>
        </div>
    </div>
)

export default GuestRsvp