import React from 'react'
import style from './styles.module.scss'

const Contact = ({ flowersColourSwap }) => {
    return (
        <section id="contact">
            <div className="centre">
                <h2>Contact Us</h2>

                <p>If you have any questions, feel free to drop us a message at <a href="mailto:hello@doherty-rowe-wedding.co.uk">hello@doherty-rowe-wedding.co.uk</a></p>

                <img src={require(flowersColourSwap ? '../../img/flowers2white.png' : '../../img/flowers2.png')} alt="" className={style.flowers} />
            </div>
        </section>
    )
}

export default Contact