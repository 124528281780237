import React from 'react'
import style from './styles.module.scss'

const LocalTaxis = ({ flowersColourSwap }) => {
    return (
        <section id="local-taxis">
            <div className="centre">
                <h2>Local Taxis</h2>

                <p>For peace of mind, we suggest you pre-book your transport for the end of the night before the day.</p>
                <p>Here are a few local taxicab companies to enquire with:</p>

                <div className={style.grid}>
                    <img src={require(flowersColourSwap ? '../../img/flowers1white.png' : '../../img/flowers1.png')} alt="" className={style.flowersL} />

                    <div>
                        <div className={style.location}>
                            <h3>Happicabs (Maldon & Chelmsford)</h3>
                            <p><a href="tel:01621883883">01621 883883</a> &mdash; <a href="https://goo.gl/maps/xZao7CE8nWVW3My49" rel="noreferrer" target="_blank">Map</a> <span className={style.chevron}>›</span></p>
                        </div>

                        <div className={style.location}>
                            <h3>Arrow Taxis (Maldon)</h3>
                            <p><a href="tel:01621855111">01621 855111</a> &mdash; <a href="https://goo.gl/maps/2iE26wxU989nAjfh7" rel="noreferrer" target="_blank">Map</a> <span className={style.chevron}>›</span></p>
                        </div>

                        <div className={style.location}>
                            <h3>Ace Cabs (Chelmsford)</h3>
                            <p><a href="tel:01245443443">01245 443443</a> &mdash; <a href="https://goo.gl/maps/oZLxEZTRDwGjpxuC7" rel="noreferrer" target="_blank">Map</a> <span className={style.chevron}>›</span></p>
                        </div>

                        <div className={style.location}>
                            <h3>Banyard Taxi (Maldon)</h3>
                            <p><a href="tel:01621850850">01621 850850</a> &mdash; <a href="https://goo.gl/maps/ueTZhTGkNDg6k4s17" rel="noreferrer" target="_blank">Map</a> <span className={style.chevron}>›</span></p>
                        </div>
                    </div>

                    <img src={require(flowersColourSwap ? '../../img/flowers1white.png' : '../../img/flowers1.png')} alt="" className={style.flowersR} />
                </div>
            </div>
        </section>
    )
}

export default LocalTaxis