import React from 'react'
import Button from '../Button'
import Input from '../Input'
import style from './styles.module.scss'

const DietaryRequirements = ({ guest, isMealChoicesDisabled, patchGuest, patchGuestLoading }) => (
    <form
        className={style.dietaryRequirements}
        onSubmit={e => {
            e.preventDefault()
            patchGuest({ column: 'dietaryRequirements', fullName: guest.fullName, value: e.target.elements.dietaryRequirements.value })
        }}
    >
        <Input
            className={style.input}
            defaultValue={guest.dietaryRequirements}
            disabled={patchGuestLoading || isMealChoicesDisabled}
            name="dietaryRequirements"
            type="text"
        />
        <Button
            className={style.submitBtn}
            disabled={patchGuestLoading || isMealChoicesDisabled}
            el="input"
            selected={true}
        >
            {guest.dietaryRequirements ? 'Save \u{2713}' : 'Save'}
        </Button>
    </form>
)

export default DietaryRequirements