import React from 'react'
import Countdown from '../../components/Countdown'
import style from './styles.module.scss'

const TheWeddingDay = ({ weddingDate }) => {
    return (
        <>
            <section id="the-wedding-day" className={style.theWeddingDay}>
                <div className="centre">
                    <h2 className="sr-only">The Wedding Day</h2>

                    <img src={require('../../img/flowers1.png')} alt="" className={style.flowersTop} />

                    <span className={`cursive ${style.handj}`}><span>Hannah</span><span>&amp;</span><span>James</span></span>
                </div>
            </section>

            <img src={require('../../img/hannahandjames.jpg')} alt="Hannah and James smiling with Durdle Door in the background. Hannah rests her hand on James' chest; the engagement ring clearly visible on her finger." className={style.photo} />

            <section>
                <div className="centre">
                    <div className={style.top}>
                        <span className={`cursive ${style.inviteyou}`}>invite you to the</span>

                        <span className={`cursive ${style.dohertyrowe}`}>Doherty-Rowe <span>Wedding</span></span>

                        <time dateTime={weddingDate} className={`cursive ${style.date}`}>Saturday 15th July 2023</time>

                        <Countdown weddingDate={weddingDate} />

                        <img src={require('../../img/flowers3.png')} alt="" className={style.flowersLeft} />
                        <img src={require('../../img/flowers3.png')} alt="" className={style.flowersRight} />
                        </div>
                </div>
            </section>

            <section id="venue">
                <div className="centre">
                    <a className={style.address} href="https://goo.gl/maps/pANJW8aqSUc1qBz1A" rel="noreferrer" target="_blank">
                        <p>The Warren Estate</p>
                        <p>Woodham Walter, near Maldon</p>
                        <p>Essex</p>
                        <p>CM9 6RW</p>
                    </a>
                </div>
            </section>

            <iframe title="Google Map with the location of The Warren Estate" height="288" className={style.map} loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJn9jH5U3m2EcRBn57_AEMaR4&zoom=12&key=AIzaSyCoH5FrK3IvnMVmCn3UHjgzSOQvolBhH3Q"></iframe>
        </>
    )
}

export default TheWeddingDay