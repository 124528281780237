import React from 'react'
import style from './styles.module.scss'

const Gifts = ({ flowersColourSwap }) => {
    return (
        <section id="gifts">
            <div className="centre">
                <h2>Gifts</h2>

                <p>Your presence will be a present enough for us! If you did want to give us a gift, a contribution to our honeymoon will be greatly appreciated.</p>

                <img src={require(flowersColourSwap ? '../../img/flowers1.png' : '../../img/flowers1white.png')} alt="" className={style.flowers} />
            </div>
        </section>
    )
}

export default Gifts