import React from 'react'
import style from './styles.module.scss'

const Input = ({ className, ...rest }) => (
    <input
        className={`${style.input} ${className}`}
        {...rest}
    />
)

export default Input