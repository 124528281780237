import React from 'react'
import Button from '../../components/Button'
import Input from '../../components/Input'
import style from './styles.module.scss'

const SongRequests = ({ guests, patchGuest, patchGuestLoading }) => {
    return (
        <section id="song-requests">
            <div className="centre">
                <h2>Song Requests</h2>
                <p>Keep the party going between band sets and request a song each!</p>

                <div className={style.grid}>
                    {guests.map((guest, key) => (
                        <form
                            className={style.form}
                            key={key}
                            onSubmit={e => {
                                e.preventDefault()
                                patchGuest({ column: 'songRequests', fullName: guest.fullName, value: e.target.elements.songRequests.value })
                            }}
                        >
                            <Input
                                className={style.input}
                                defaultValue={guest.songRequests}
                                disabled={patchGuestLoading}
                                name="songRequests"
                                type="text"
                            />
                            <Button
                                className={style.submitBtn}
                                disabled={patchGuestLoading}
                                el="input"
                                selected={true}
                            >
                                {guest.songRequests ? 'Save \u{2713}' : 'Save'}
                            </Button>
                        </form>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default SongRequests