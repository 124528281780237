import React from 'react'
import GuestMeals from '../../components/GuestMeals'
import style from './styles.module.scss'

const MealChoices = ({ guests, isMealChoicesDisabled, isMealChoicesVisible, onChangeMealChoice, patchGuest, patchGuestLoading }) => {
    return (
        <section id="meal-choices">
            <div className="centre">
                <h2>Meal Choices</h2>

                {isMealChoicesVisible ? (
                    <>
                        <p>Please select your meal choices for each guest using the buttons below.</p>

                        {guests.map((guest, key) => (
                            <React.Fragment key={key}>
                                {guest.fullName !== 'Yara' && <GuestMeals guest={guest} isMealChoicesDisabled={isMealChoicesDisabled} onChangeMealChoice={onChangeMealChoice} patchGuest={patchGuest} patchGuestLoading={patchGuestLoading} />}
                            </React.Fragment>
                        ))}

                        <img src={require('../../img/flowers2.png')} alt="" className={style.flowers} />
                    </>
                ) : (
                    <p>Meal choices will be available in due course. We'll send you an email when they're ready.</p>
                )}
            </div>
        </section>
    )
}

export default MealChoices