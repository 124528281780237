import React from 'react'
import style from './styles.module.scss'

const LocalAccommodation = ({ flowersColourSwap }) => {
    return (
        <section id="local-accommodation">
            <div className="centre">
                <h2>Local Accommodation</h2>

                <p>We would love for you to be able to stay until the end without worrying about a long journey home.</p>
                <p>Here are some recommendations for local overnight accommodation.</p>

                <div className={style.grid}>
                    <img src={require(flowersColourSwap ? '../../img/flowers1white.png' : '../../img/flowers1.png')} alt="" className={style.flowersL} />

                    <div>
                        <div className={style.location}>
                            <h3>On Site</h3>
                            <p><a href="https://www.hoseasons.co.uk/lodges/warren-lodges-herb" rel="noreferrer" target="_blank">Warren Lodges</a> <span className={style.chevron}>›</span></p>
                        </div>

                        <div className={style.location}>
                            <h3>Chelmsford</h3>
                            <p><a href="https://www.premierinn.com/gb/en/hotels/england/essex/chelmsford/chelmsford-city-centre.html?INNID=CHECIT&ARRdd=15&ARRmm=07&ARRyyyy=2023&NIGHTS=1&ROOMS=1&ADULT1=2&CHILD1=0&COT1=0&INTTYP1=DB&SID=4&ISH=true" rel="noreferrer" target="_blank">Premier Inn</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://www.travelodge.co.uk/hotels/384/Chelmsford-hotel?checkIn=15/07/2023&checkOut=16/07/2023&rooms[0][adults]=2&rooms[0][children]=0" rel="noreferrer" target="_blank">Travelodge</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://uk.hotels.com/ho1180951008/rothmans-bed-and-breakfast-chelmsford-united-kingdom/" rel="noreferrer" target="_blank">Rothmans Bed and Breakfast</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://goo.gl/maps/8DXcdxqTbevCVkYe6" rel="noreferrer" target="_blank">Pontlands Park Hotel</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://g.page/countyhotelchelmsford" rel="noreferrer" target="_blank">County Hotel</a> <span className={style.chevron}>›</span></p>
                        </div>

                        <div className={style.location}>
                            <h3>Maldon</h3>
                            <p><a href="https://goo.gl/maps/hzmYZb1b1xaGst698" rel="noreferrer" target="_blank">The Blue Boar</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://goo.gl/maps/rcTNu9YiaLM2yUjQ6" rel="noreferrer" target="_blank">The White Horse Inn</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://goo.gl/maps/T44MGvb4HAaWvVbi7" rel="noreferrer" target="_blank">Travelodge</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://goo.gl/maps/PLYYaPc2WJ33zTBe7" rel="noreferrer" target="_blank">Le Bouchon</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://goo.gl/maps/3T3ibubRm9U9DVPY7" rel="noreferrer" target="_blank">The Limes</a> <span className={style.chevron}>›</span></p>
                        </div>

                        <div className={style.location}>
                            <h3>Places To Look</h3>
                            <p><a href="https://www.airbnb.co.uk" rel="noreferrer" target="_blank">Airbnb</a> <span className={style.chevron}>›</span></p>
                            <p><a href="https://www.expedia.co.uk" rel="noreferrer" target="_blank">Expedia</a> <span className={style.chevron}>›</span></p>
                        </div>
                    </div>

                    <img src={require(flowersColourSwap ? '../../img/flowers1white.png' : '../../img/flowers1.png')} alt="" className={style.flowersR} />
                </div>
            </div>
        </section>
    )
}

export default LocalAccommodation