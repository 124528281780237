import React, { useState } from 'react'
import style from './styles.module.scss'

const Header = ({ guests }) => {
    const [navOpen, setNavOpen] = useState(false)

    return (
        <header className={navOpen ? style.navOpen : undefined}>
            <button className={style.toggle} onClick={() => setNavOpen(!navOpen)}><span className="sr-only">{navOpen ? 'Close menu' : 'Open menu'}</span>{navOpen ? '×' : '☰'}</button>
            <nav>
                <a className={style.item} href="#the-wedding-day" onClick={() => setNavOpen(false)}>The Wedding Day</a>
                {!!guests.length ? (
                    <>
                        <a className={style.item} href="#rsvp" onClick={() => setNavOpen(false)}>RSVP</a>
                        {guests[0].invitedToCeremony && <a className={style.item} href="#meal-choices" onClick={() => setNavOpen(false)}>Meal Choices</a>}
                        <a className={style.item} href="#song-requests" onClick={() => setNavOpen(false)}>Song Requests</a>
                        <a className={style.item} href="#schedule" onClick={() => setNavOpen(false)}>Schedule</a>
                    </>
                ) : (
                    <a className={style.item} href="#invitation-code" onClick={() => setNavOpen(false)}>Invitation Code</a>
                )}
                <a className={style.item} href="#local-accommodation" onClick={() => setNavOpen(false)}>Local Accommodation</a>
                <a className={style.item} href="#local-taxis" onClick={() => setNavOpen(false)}>Local Taxis</a>
                <a className={style.item} href="#gifts" onClick={() => setNavOpen(false)}>Gifts</a>
                {/* <a className={style.item} href="#faq" onClick={() => setNavOpen(false)}>FAQ</a> */}
                <a className={style.item} href="#contact" onClick={() => setNavOpen(false)}>Contact Us</a>
            </nav>
            <div className={style.overlay} onClick={() => setNavOpen(false)} />
        </header>
    )
}

export default Header