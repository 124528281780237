import React from 'react'
import GuestRsvp from '../../components/GuestRsvp'
import style from './styles.module.scss'

const Event = ({
    date,
    desc,
    divider = true,
    guests,
    isRsvpDisabled,
    location,
    mapLink,
    patchGuest,
    patchGuestLoading,
    rsvpRequired,
    sheetKey,
    time,
    title,
}) => (
    <div className={`${style.event} ${!divider && style.noDivider}`}>
        <div className={style.column}>
            <h3 className={style.h3}>{title}</h3>
            <p className={style.date}>{date}</p>
            <p className={style.time}>{time}</p>
            <p className={style.location}><a href={mapLink} rel="noreferrer" target="_blank">{location}</a></p>
            <p className={style.desc} dangerouslySetInnerHTML={{ __html: desc }} />
        </div>

        <div className={style.column}>
            {rsvpRequired && (
                <>
                    {!!guests.length && guests.map(guest => (
                        <GuestRsvp guest={guest} isRsvpDisabled={isRsvpDisabled} key={guest.fullName} patchGuest={patchGuest} patchGuestLoading={patchGuestLoading} sheetKey={sheetKey} />
                    ))}
                </>
            )}
        </div>
    </div>
)

const Rsvp = ({ guests, isRsvpDisabled, patchGuest, patchGuestLoading }) => (
    <section id="rsvp">
        <div className="centre">
            <h2>RSVP</h2>

            <p>Please RSVP by 30th April using the buttons below.</p>
            <p>We love your children but we are requesting that apart from immediate family, this wedding will be adult-only.</p>

            {guests[0].invitedToWelcomeDrinks && (
                <Event
                    date="Friday 14th July"
                    desc="Come and join us for a drink the night before the big day!"
                    guests={guests}
                    isRsvpDisabled={isRsvpDisabled}
                    location="The Blue Lion, Great Baddow"
                    mapLink="https://maps.app.goo.gl/Gr4d6qvHm17v6Y288"
                    patchGuest={patchGuest}
                    patchGuestLoading={patchGuestLoading}
                    rsvpRequired={true}
                    sheetKey="rsvpWelcomeDrinks"
                    time="6:00pm"
                    title="Welcome Drinks"
                />
            )}

            {guests[0].invitedToCeremony && (
                <Event
                    date="Saturday 15th July"
                    desc="We plan to get married outdoors, weather permitting."
                    divider={false}
                    guests={guests}
                    isRsvpDisabled={isRsvpDisabled}
                    location="The Warren Estate"
                    mapLink="https://goo.gl/maps/pANJW8aqSUc1qBz1A"
                    patchGuest={patchGuest}
                    patchGuestLoading={patchGuestLoading}
                    rsvpRequired={true}
                    sheetKey="rsvpCeremony"
                    time="12:30pm"
                    title="Ceremony"
                />
            )}

            {guests[0].invitedToReception && (
                <Event
                    date="Saturday 15th July"
                    desc="Buffet, drinks, live band and plenty of dancing."
                    guests={guests}
                    isRsvpDisabled={isRsvpDisabled}
                    location="The Warren Estate"
                    mapLink="https://goo.gl/maps/pANJW8aqSUc1qBz1A"
                    patchGuest={patchGuest}
                    patchGuestLoading={patchGuestLoading}
                    rsvpRequired={guests[0].invitedToCeremony ? false : true}
                    sheetKey="rsvpReception"
                    time="6:30pm"
                    title="Reception"
                />
            )}

            {guests[0].invitedToMorning && (
                <Event
                    date="Sunday 16th July"
                    desc="Laid-back family event in the Oak Room at The Warren Estate for food & drinks. Come and go as you please!<br /><br />If you're interested in having a roast dinner please let us know in advance."
                    guests={guests}
                    isRsvpDisabled={isRsvpDisabled}
                    location="The Warren Estate"
                    mapLink="https://goo.gl/maps/pANJW8aqSUc1qBz1A"
                    patchGuest={patchGuest}
                    patchGuestLoading={patchGuestLoading}
                    rsvpRequired={true}
                    sheetKey="rsvpMorning"
                    time="11:00am onwards"
                    title="Morning After"
                />
            )}

            <img src={require('../../img/flowers2.png')} alt="" className={style.flowers} />
        </div>
    </section>
)

export default Rsvp